.rodimus-complex-front {
  --_rodimus-complex-margin: 0rem;
  --_optimus-complex-divider-color: var(--brick-colors-supportiveNoneOpaqueFg);
  --_optimus-complex-x-divider-weight: 1px;
  --_optimus-complex-bg: var(--brick-colors-supportiveNoneBg);
  --_optimus-complex-fg: var(--brick-colors-supportiveNoneFg);
  --_optimus-complex-y-padding: var(--brick-space-groupYGapM);
  --_optimus-complex-x-padding: var(--brick-space-groupXGapM);
  --_optimus-complex-y-padding-divider: var(--brick-space-groupYDividerM);
  --_optimus-complex-y-padding-top: var(--_optimus-complex-y-padding-divider);

  background-color: var(--_optimus-complex-bg);
  color: var(--_optimus-complex-fg);
  border-radius: var(--brick-radii-teaser);
  box-shadow: var(--brick-shadows-boxShadowTeaser);
  overflow: hidden;
  margin-bottom: var(--brick-space-deckYGapM);
}

.rodimus-complex-front .rodimus-front {
  margin-bottom: 0 !important;
}

.rodimus-complex-front > header {
  padding: var(--brick-space-cardYM) var(--brick-space-cardXM);
  margin: 0;
  background: transparent
    linear-gradient(
      var(--_optimus-complex-divider-color),
      var(--_optimus-complex-divider-color)
    )
    no-repeat bottom /
    calc(
      100vw - (var(--_rodimus-complex-margin) * 2) -
        (var(--_optimus-complex-x-padding) * 2)
    )
    1px;
}

.rodimus-complex-front > header h2 {
  padding: 0;
  margin: 0;
  text-align: left;
  text-transform: initial;
  font-family: var(--brick-fonts-groupTitleL);
  letter-spacing: var(--brick-letterSpacings-groupTitleL);
  font-size: var(--brick-fontSizes-groupTitleL);
  font-weight: var(--brick-fontWeights-groupTitleL);
  line-height: var(--brick-lineHeights-groupTitleL);
  background-color: inherit;
  margin: 0;
}

.rodimus-complex-front:not(.list-horizontal) {
  --b-teaser-boxShadow: 0;
  --b-teaser-borderRadius: 0;
  --b-teaser-borderRadius-image: var(--brick-radii-groupTeaserImage);
  --b-teaser-padding: var(--brick-space-groupYTeaserM)
    var(--brick-space-groupXTeaserM);
  --b-teaser-padding-content-right: 0;
  --b-teaser-padding-content-bottom: 0;
  --b-teaser-padding-content-left: 0;
  --b-teaser-padding-opinion-bubble-wrap: 0;
  --b-teaser-padding-footer: 0;
  --b-teaser-padding-footer-content: var(--brick-space-teaserFooterTipusTopM) 0
    0 0;
  --b-teaser-padding-noImageContent: 0;
  --b-teaser-color-divider: currentColor;
  --b-teaser-margin-divider: 0;
}

.rodimus-complex-front:not(.list-horizintal):has([data-teaser-type='feature']) {
  --b-teaser-padding-pill-wrap: 0;
  --b-teaser-padding-premium-wrap: var(--brick-space-teaserStackCM) 0 0 0;
}

.rodimus-complex-front:not(.list-horizontal) [data-teaser-type='sport'] {
  --b-teaser-padding-sport-title: var(--brick-space-teaserStackAL) 0 0 0;
}

.rodimus-complex-front:not(.list-horizontal)
  optimus-element[teaser-type='video']
  > amedia-include,
.rodimus-complex-front.rodimus-complex-front:not(.list-horizontal)
  amedia-include:has(brick-player) {
  padding: var(--b-teaser-padding);
}

.rodimus-complex-front:not(.list-horizontal) [version='imageLeft'] .body {
  --b-teaser-padding-content-top: 0;
  --b-teaser-padding-content-right: 0;
  --b-teaser-padding-content-bottom: 0;
  --b-teaser-padding-content-left: var(--brick-space-teaserContentYInsetM);
}

.rodimus-complex-front:not(.list-horizontal)
  [version='imageLeft']
  [data-teaser-type='sport'] {
  --b-teaser-padding-sport-pills: 0 0 0 var(--brick-space-x3);
  --b-teaser-padding-sport-title: 0 0 0 var(--brick-space-x3);
  --b-teaser-padding-sport-premium: 0;
}

.rodimus-complex-front:not(.list-horizontal)
  [version='imageLeft']
  [data-teaser-type='sport']
  .mirror {
  --b-teaser-padding-sport-pills: 0 var(--brick-space-x3) 0 0;
  --b-teaser-padding-sport-title: 0 var(--brick-space-x3) 0 0;
  --b-teaser-padding-sport-premium: var(--brick-space-x3);
}

@media screen and (min-width: 533px) {
  .rodimus-complex-front {
    --_optimus-complex-y-padding: var(--brick-space-groupYGapL);
    --_optimus-complex-x-padding: var(--brick-space-groupXGapL);
    --_optimus-complex-y-padding-divider: var(--brick-space-groupYDividerL);
  }
  .rodimus-complex-front:not(.list-horizontal) {
    --b-teaser-padding: var(--brick-space-groupYTeaserL)
      var(--brick-space-groupXTeaserL);
    --b-teaser-padding-footer-content: var(--brick-space-teaserFooterTipusTopL)
      0 0 0;
  }
  .rodimus-complex-front > header {
    padding: var(--brick-space-cardYL) var(--brick-space-cardXL);
    background: transparent
      linear-gradient(
        var(--_optimus-complex-divider-color),
        var(--_optimus-complex-divider-color)
      )
      no-repeat bottom / 100% 1px;
  }
}

@media screen and (min-width: 532px) {
  .rodimus-complex-front {
    margin-bottom: var(--brick-space-deckYGapL);
  }
}

@media screen and (max-width: 532px) {
  .rodimus-complex-front {
    box-sizing: border-box;
  }
  .rodimus-complex-front:not(.list-horizontal) [data-teaser-type='sport'] {
    --b-teaser-padding-sport-title: var(--brick-space-teaserStackAM) 0 0 0;
  }
}

/* So that groups will not disappear 
   when parallax is visible */
.rodimus-complex-front {
  position: relative;
  z-index: 3;
}

.rodimus-complex-front:not([class*='background']):not([class*='custom']):not(
    .list-horizontal
  ):not(:has(.solo-group)):not(:has(optimus-element:only-child)) {
  --b-teaser-color-bg: var(--brick-colors-supportiveNoneBg);
  --b-teaser-color-fg: var(--brick-colors-supportiveNoneFg);
  --b-opinion-bubble-color-bg: var(--brick-colors-teaserBubbleBg);
  --b-pill-color-bg: var(--brick-colors-pillNoneFilledBg);
  --b-pill-color-fg: var(--brick-colors-pillNoneFilledFg);
  --b-pill-color-breakingBg: var(--brick-colors-pillNoneFilledBg);
  --b-pill-color-breakingFg: var(--brick-colors-pillNoneFilledFg);
  --b-pillbox-color-divider: var(--brick-colors-pillNoneFilledDivider);
}
.rodimus-complex-front:not([class*='background']):not([class*='custom']):not(
    .list-horizontal
  ):not(:has(.solo-group)):not(:has(optimus-element:only-child))
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillNonePulseBg);
  --b-icon-color-breaking-pulseStart: var(--brick-colors-pillNonePulseStartBg);
  --b-icon-color-breaking-pulseEnd: var(---brick-colors-pillNonePulseEndBg);
}
.rodimus-complex-front:not([class*='background']):not([class*='custom']):not(
    .list-horizontal
  ):not(:has(.solo-group)):not(:has(optimus-element:only-child))
  brick-pill[data-filled='true']
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillNoneFilledPulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillNoneFilledPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(
    ---brick-colors-pillNoneFilledPulseEndBg
  );
}

.rodimus-complex-front .rodimus-front article footer {
  color: inherit;
  background-color: inherit;
}

.optimus-background-black {
  --_optimus-complex-bg: var(--brick-colors-supportiveBlackBg);
  --_optimus-complex-fg: var(--brick-colors-supportiveBlackFg);
  --_optimus-complex-divider-color: var(--brick-colors-supportiveBlackOpaqueFg);
  --b-teaser-color-bg: var(--brick-colors-supportiveBlackBg);
  --b-teaser-color-fg: var(--brick-colors-supportiveBlackFg);
  --b-teaser-color-opinion-bubble-bg: var(
    --brick-colors-supportiveBlackOpaqueFg
  );
  --b-countdown-fg: var(--brick-colors-supportiveBlackFg);
  --b-teaser-color-breakingBoost: var(--brick-colors-supportiveBlackFg);
  --b-pill-color-bg: var(--brick-colors-pillBlackFilledBg);
  --b-pill-color-fg: var(--brick-colors-pillBlackFilledFg);
  --b-pill-color-breakingBg: var(--brick-colors-pillBlackFilledBreakingBg);
  --b-pill-color-breakingFg: var(--brick-colors-pillBlackFilledBreakingFg);
  --b-pillbox-color-divider: var(--brick-colors-pillBlackFilledDivider);
}
.optimus-background-black brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillBlackPulseBg);
  --b-icon-color-breaking-pulseStart: var(--brick-colors-pillBlackPulseStartBg);
  --b-icon-color-breaking-pulseEnd: var(--brick-colors-pillBlackPulseEndBg);
}
.optimus-background-black
  brick-pill[data-filled='true']
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillBlackFilledPulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillBlackFilledPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(
    --brick-colors-pillBlackFilledPulseEndBg
  );
}

.optimus-background-yellow {
  --_optimus-complex-bg: var(--brick-colors-supportiveHighlightBg);
  --_optimus-complex-fg: var(--brick-colors-supportiveHighlightFg);
  --_optimus-complex-divider-color: var(
    --brick-colors-supportiveHighlightOpaqueFg
  );
  --b-teaser-color-bg: var(--brick-colors-supportiveHighlightBg);
  --b-teaser-color-fg: var(--brick-colors-supportiveHighlightFg);
  --b-teaser-color-opinion-bubble-bg: var(
    --brick-colors-supportiveHighlightOpaqueFg
  );
  --b-countdown-fg: var(--brick-colors-supportiveHighlightFg);
  --b-teaser-color-breakingBoost: var(--brick-colors-supportiveHighlightFg);
  --b-pill-color-bg: var(--brick-colors-pillHighlightFilledBg);
  --b-pill-color-fg: var(--brick-colors-pillHighlightFilledFg);
  --b-pill-color-breakingBg: var(--brick-colors-pillHighlightFilledBreakingBg);
  --b-pill-color-breakingFg: var(--brick-colors-pillHighlightFilledBreakingFg);
  --b-pillbox-color-divider: var(--brick-colors-pillHighlightFilledDivider);
}
.optimus-background-yellow brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillHighlightPulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillHighlightPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(--brick-colors-pillHighlightPulseEndBg);
}
.optimus-background-yellow
  brick-pill[data-filled='true']
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillHighlightFilledPulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillHighlightFilledPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(
    --brick-colors-pillHighlightFilledPulseEndBg
  );
}

.optimus-background-finance {
  background-color: var(--swatchHighlights-finance);
  color: var(--swatchMono-black);
}

.rodimus-complex-front.optimus-custom-one {
  --_optimus-complex-bg: var(--custom-background-color-one);
  --_optimus-complex-fg: var(--custom-background-color-one-front);
  --_optimus-complex-divider-color: var(
    --custom-background-color-one-opaque-front
  );
  --b-teaser-color-bg: var(
    --custom-background-color-one,
    var(--brick-colors-supportiveCustomOneBg)
  );
  --b-teaser-color-fg: var(
    --custom-background-color-one-front,
    var(--brick-colors-supportiveCustomOneFg)
  );
  --b-teaser-color-opinion-bubble-bg: var(
    --custom-background-color-one-opaque-front
  );
  --b-teaser-color-breakingBoost: var(--custom-background-color-one-front);
  --b-pill-color-bg: var(--custom-background-color-one);
  --b-pill-color-fg: var(--custom-background-color-one-front);
  --b-pill-color-breakingBg: var(--custom-background-color-one);
  --b-pill-color-breakingFg: var(--custom-background-color-one-front);
  --b-pillbox-color-divider: var(--brick-colors--pillCustomOneFilledDivider);
}
.rodimus-complex-front.optimus-custom-one
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillCustomOnePulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillCustomOnePulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(--brick-colors-pillCustomOnePulseEndBg);
}
.rodimus-complex-front.optimus-custom-one
  brick-pill[data-filled='true']
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillCustomOneFilledPulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillCustomOneFilledPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(
    --brick-colors-pillCustomOneFilledPulseEndBg
  );
}

.rodimus-complex-front.optimus-custom-two {
  --_optimus-complex-bg: var(--custom-background-color-two);
  --_optimus-complex-fg: var(--custom-background-color-two-front);
  --_optimus-complex-divider-color: var(
    --custom-background-color-two-opaque-front
  );
  --b-teaser-color-bg: var(
    --custom-background-color-two,
    var(--brick-colors-supportiveCustomTwoBg)
  );
  --b-teaser-color-fg: var(
    --custom-background-color-two-front,
    var(--brick-colors-supportiveCustomTwoFg)
  );
  --b-teaser-color-opinion-bubble-bg: var(
    --custom-background-color-two-opaque-front
  );
  --b-teaser-color-breakingBoost: var(--custom-background-color-two-front);
  --b-pill-color-bg: var(--custom-background-color-two);
  --b-pill-color-fg: var(--custom-background-color-two-front);
  --b-pill-color-breakingBg: var(--custom-background-color-two);
  --b-pill-color-breakingFg: var(--custom-background-color-two-front);
  --b-pillbox-color-divider: var(--brick-colors--pillCustomTwoFilledDivider);
}
.rodimus-complex-front.optimus-custom-two
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillCustomTwoPulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillCustomTwoPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(--brick-colors-pillCustomTwoPulseEndBg);
}
.rodimus-complex-front.optimus-custom-two
  brick-pill[data-filled='true']
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillCustomTwoFilledPulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillCustomTwoFilledPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(
    --brick-colors-pillCustomTwoFilledPulseEndBg
  );
}

.rodimus-complex-front.optimus-custom-three {
  --_optimus-complex-bg: var(--custom-background-color-three);
  --_optimus-complex-fg: var(--custom-background-color-three-front);
  --_optimus-complex-divider-color: var(
    --custom-background-color-three-opaque-front
  );
  --b-teaser-color-bg: var(
    --custom-background-color-three,
    var(--brick-colors-supportiveCustomThreeBg)
  );
  --b-teaser-color-fg: var(
    --custom-background-color-three-front,
    var(--brick-colors-supportiveCustomThreeFg)
  );
  --b-teaser-color-opinion-bubble-bg: var(
    --custom-background-color-three-opaque-front
  );
  --b-teaser-color-breakingBoost: var(--custom-background-color-three-front);
  --b-pill-color-bg: var(--custom-background-color-three);
  --b-pill-color-fg: var(--custom-background-color-three-front);
  --b-pill-color-breakingBg: var(--custom-background-color-three);
  --b-pill-color-breakingFg: var(--custom-background-color-three-front);
  --b-pillbox-color-divider: var(--brick-colors--pillCustomThreeFilledDivider);
}
.rodimus-complex-front.optimus-custom-three
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillCustomThreePulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillCustomThreePulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(
    --brick-colors-pillCustomThreePulseEndBg
  );
}
.rodimus-complex-front.optimus-custom-three
  brick-pill[data-filled='true']
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillCustomThreeFilledPulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillCustomThreeFilledPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(
    --brick-colors-pillCustomThreeFilledPulseEndBg
  );
}

.rodimus-complex-front.optimus-background-opinion {
  --_optimus-complex-bg: var(--opinion-background-color);
  --_optimus-complex-fg: var(--opinion-color-front);
  --_optimus-complex-divider-color: var(--opinion-color-opaque-front);
  --b-teaser-color-bg: var(
    --opinion-background-color,
    var(--brick-colors-supportiveOpinionBg)
  );
  --b-teaser-color-fg: var(
    --opinion-color-front,
    var(--brick-colors-supportiveOpinionFg)
  );
  --b-teaser-color-opinion-bubble-bg: var(--opinion-color-opaque-front);
  --b-teaser-color-breakingBoost: var(--opinion-color-front);
  --b-pill-color-bg: var(--opinion-background-color);
  --b-pill-color-fg: var(--opinion-color-front);
  --b-pill-color-breakingBg: var(--opinion-background-color);
  --b-pill-color-breakingFg: var(--opinion-color-front);
}
.rodimus-complex-front.optimus-background-opinion
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--opinion-color-front);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillOpinionPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(--brick-colors-pillOpinionPulseEndBg);
}
.rodimus-complex-front.optimus-background-opinion
  brick-pill[data-filled='true']
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillOpinionFilledPulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillOpinionFilledPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(
    --brick-colors-pillOpinionFilledPulseEndBg
  );
}

.rodimus-complex-front.optimus-background-custom-sport {
  --_optimus-complex-bg: var(--brick-colors-supportiveNoneBg);
  --_optimus-complex-fg: var(--brick-colors-supportiveNoneFg);
  --b-teaser-color-bg: var(--brick-colors-supportiveSportBg);
  --b-teaser-color-fg: var(--brick-colors-supportiveSportFg);
  --b-teaser-padding-content-top: var(--brick-space-x3);
  --b-teaser-padding-content-bottom: var(--brick-space-x3);
  --b-teaser-color-breakingBoost: var(--brick-colors-supportiveSportFg);
  --b-pill-color-bg: var(--brick-colors-pillNoneFilledBg);
  --b-pill-color-fg: var(--brick-colors-pillNoneFilledFg);
  --b-pill-color-breakingBg: var(--brick-colors-pillNoneFilledBg);
  --b-pill-color-breakingFg: var(--brick-colors-pillNoneFilledFg);
  --b-pillbox-color-divider: var(--brick-colors-pillNoneFilledDivider);
}
.rodimus-complex-front.optimus-background-custom-sport
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillSportPulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillSportFilledPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(
    --brick-colors-pillSportFilledPulseEndBg
  );
}
.rodimus-complex-front.optimus-background-custom-sport
  brick-pill[data-filled='true']
  brick-icon-v2[data-icon-id='pill-breaking'] {
  --b-icon-color: var(--brick-colors-pillNoneFilledPulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillNoneFilledPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(
    ---brick-colors-pillNoneFilledPulseEndBg
  );
}

/* brick-pill with filled opinion skin should have opinion color no matter the group background color */
.rodimus-complex-front brick-pill[data-skin='opinion'][data-filled='true'] {
  --b-pill-color-bg: var(--opinion-background-color);
  --b-pill-color-fg: var(--opinion-color-front);
  --b-pill-color-breakingBg: var(--opinion-background-color);
  --b-pill-color-breakingFg: var(--opinion-color-front);
  --b-icon-color: var(--opinion-color-front);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillOpinionPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(--brick-colors-pillOpinionPulseEndBg);
}

/* brick-pill in teaser with marker effect should have teaser skin color no matter the group background color */
.rodimus-complex-front
  brick-teaser-v17:has(.custom-one):has([itemprop='marker']) {
  --b-pill-color-bg: var(--custom-background-color-one);
  --b-pill-color-fg: var(--custom-background-color-one-front);
  --b-pill-color-breakingBg: var(--custom-background-color-one);
  --b-pill-color-breakingFg: var(--custom-background-color-one-front);
  --b-pillbox-color-divider: var(--brick-colors--pillCustomOneFilledDivider);
  --b-icon-color: var(--brick-colors-pillCustomOneFilledPulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillCustomOneFilledPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(
    --brick-colors-pillCustomOneFilledPulseEndBg
  );
}
.rodimus-complex-front
  brick-teaser-v17:has(.custom-two):has([itemprop='marker']) {
  --b-pill-color-bg: var(--custom-background-color-two);
  --b-pill-color-fg: var(--custom-background-color-two-front);
  --b-pill-color-breakingBg: var(--custom-background-color-two);
  --b-pill-color-breakingFg: var(--custom-background-color-two-front);
  --b-pillbox-color-divider: var(--brick-colors--pillCustomTwoFilledDivider);
  --b-icon-color: var(--brick-colors-pillCustomTwoFilledPulseBg);
  --b-icon-color-breaking-pulseStart: var(
    --brick-colors-pillCustomTwoFilledPulseStartBg
  );
  --b-icon-color-breaking-pulseEnd: var(
    --brick-colors-pillCustomTwoFilledPulseEndBg
  );
}

.rodimus-complex-front.optimus-background-custom-sport .imageLeft {
  --b-teaser-padding-content-top: 0;
  --b-teaser-padding-content-bottom: 0;
}

.rodimus-complex-front.optimus-background-custom-sport .imageLeft .mirror {
  --b-teaser-padding-content-right: 0;
  --b-teaser-padding-content-left: 0;
}

.rodimus-complex-front .countdown {
  background-color: inherit;
  color: inherit;
}

.rodimus-complex-front .countdown:has(brick-countdown-v0) hr {
  display: block;
  background-color: inherit;
  color: inherit;
}

.rodimus-complex-front
  :not(.skin):not(.opinion)
  .body
  brick-pill[data-filled='false'] {
  color: inherit;
  background-color: inherit;
}

/* Temporary styling for breaking news icon until it is delivered from brick */
header h2 [data-icon-id='pill-breaking'] {
  position: relative;
  top: 2px;
  margin-inline-start: calc(var(--brick-space-x1) * 0.2);
  margin-inline-end: calc(var(--brick-space-x1) * 0.3);
}

header h2 [data-icon-id='pill-breaking'] .svg-wrap {
  --brick--pulseColor70: var(--brick-colors-pillNonePulseStartBg);
  --brick--pulseColor0: var(--brick-colors-pillNonePulseEndBg);
}

header h2 [data-icon-id='pill-breaking'] svg {
  fill: var(--brick-colors-pillNonePulseBg);
  color: var(--brick-colors-pillNonePulseBg);
  display: inline-flex;
  width: 16px;
  height: 16px;
}

@media screen and (min-width: 500px) {
  header h2 [data-icon-id='pill-breaking'] {
    top: 3px;
    margin-inline-end: calc(var(--brick-space-x1) * 0.7);
  }

  header h2 [data-icon-id='pill-breaking'] svg {
    width: 22px;
    height: 22px;
  }
}

/* Overrides for different group background colors */
.optimus-custom-one > header h2 [data-icon-id='pill-breaking'] .svg-wrap {
  --brick--pulseColor70: var(--brick-colors-pillCustomOnePulseStartBg);
  --brick--pulseColor0: var(--brick-colors-pillCustomOnePulseEndBg);
}
.optimus-custom-one > header h2 [data-icon-id='pill-breaking'] svg {
  fill: var(--brick-colors-pillCustomOnePulseBg);
  color: var(--brick-colors-pillCustomOnePulseBg);
}

.optimus-custom-two > header h2 [data-icon-id='pill-breaking'] .svg-wrap {
  --brick--pulseColor70: var(--brick-colors-pillCustomTwoPulseStartBg);
  --brick--pulseColor0: var(--brick-colors-pillCustomTwoPulseEndBg);
}
.optimus-custom-two > header h2 [data-icon-id='pill-breaking'] svg {
  fill: var(--brick-colors-pillCustomTwoPulseBg);
  color: var(--brick-colors-pillCustomTwoPulseBg);
}

.optimus-background-black
  > header
  header
  h2
  [data-icon-id='pill-breaking']
  .svg-wrap {
  --brick--pulseColor70: var(--brick-colors-pillBlackPulseStartBg);
  --brick--pulseColor0: var(--brick-colors-pillBlackPulseEndBg);
}
.optimus-background-black > header h2 [data-icon-id='pill-breaking'] svg {
  fill: var(--brick-colors-pillBlackPulseBg);
  color: var(--brick-colors-pillBlackPulseBg);
}

.optimus-background-yellow
  > header
  h2
  [data-icon-id='pill-breaking']
  .svg-wrap {
  --brick--pulseColor70: var(--brick-colors-pillHighlightPulseStartBg);
  --brick--pulseColor0: var(--brick-colors-pillHighlightPulseEndBg);
}
.optimus-background-yellow > header h2 [data-icon-id='pill-breaking'] svg {
  fill: var(--brick-colors-pillHighlightPulseBg);
  color: var(--brick-colors-pillHighlightPulseBg);
}

/* bandage for brick-carousel width-bug */
.rodimus-complex-front amedia-include[rendered='true']:has(brick-carousel) {
  overflow: hidden;
}

/* solo-styles start */

.solo-group brick-teaser-v17:has(.skin) {
  --b-teaser-padding: var(--brick-space-teaserSkinInsetM);
}

.solo-group brick-teaser-v17 .teaser_body {
  grid-template-columns: '1fr';
  column-gap: var(--brick-space-teaserStackAM);
}

.solo-group brick-teaser-v17 .has-image .teaser_body:has(figure) {
  --brick-space-teaserStackCL: var(--brick-space-teaserStackAM);
  grid-column: 2 / -1;
  grid-template-columns: 30% 1fr;
}

.solo-group brick-teaser-v17 .title_container .title {
  overflow-wrap: break-word;
  hyphens: manual;
}

.solo-group brick-teaser-v17 .has-image .overlay_container brick-pillbox {
  display: none;
}

.solo-group brick-teaser-v17 .has-image .body .top-pills {
  display: block;
}

.solo-group brick-teaser-v17 .teaser_body .marker {
  --brick-space-teaserStackAM: 0;
  --brick-space-teaserStackAL: 0;
}

.solo-group brick-teaser-v17 .has-image .teaser_body:has(figure).mirror {
  grid-template-columns: 1fr 30%;
}

.solo-group brick-teaser-v17 .teaser_body.mirror figure {
  grid-column: 2/-1;
  grid-row: 1/1;
  box-sizing: border-box;
}
.solo-group brick-teaser-v17 .teaser_body.mirror .body {
  grid-column: 1/1;
}

.solo-group
  brick-teaser-v17:has([data-breaking-news='true'])
  .title_container
  .title {
  --brick-teaser-solo-cqi: 1.9cqi;
}

.solo-group
  brick-teaser-v17:has([data-breaking-news='true']):has(breaking-text)
  .title_container
  .title {
  --brick-teaser-solo-cqi: 1.2cqi;
}

.solo-group:not(.opinion):not(.marker).skin {
  --b-teaser-padding: var(--brick-space-teaserSkinInsetM);
}

/* solo-group opinion styles */
.solo-group brick-teaser-v17[data-teaser-type='opinion'] .opinion_body {
  --b-teaser-padding-content-top: var(--brick-space-teaserContentYInsetM);
  --b-teaser-padding-content-right: var(--brick-space-teaserContentXInsetM);
  --b-teaser-padding-content-bottom: var(--brick-space-teaserContentYInsetM);
  --b-teaser-padding-content-left: var(--brick-space-teaserContentXInsetM);
  --b-teaser-padding-overlay: var(--brick-space-teaserContentXInsetM)
    var(--brick-space-teaserContentXInsetM) 0 0;
  grid-template-areas: 'title premium';
  grid-auto-flow: row;
  position: relative;
}
.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .title_container
  .title {
  --brick-teaser-solo-cqi: 0.7cqi;
  --brick-teaser-cqi-clamp: clamp(
    var(--brick-fontSizes-titleOpinionXs),
    var(--brick-sizes-titleCqi) + var(--brick-fontSizes-titleCqiBase),
    var(--brick-fontSizes-titleOpinionM)
  );
}

.solo-group brick-teaser-v17[data-teaser-type='opinion'] .opinion_body.pills {
  grid-template-areas: 'pills pills' 'title premium';
}
.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .has-image
  .opinion_body {
  grid-template-columns: 30% auto;
  grid-template-areas: 'image title' 'image premium';
}
.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .has-image
  .opinion_body.pills {
  grid-template-areas: 'image pills' 'image title' 'image premium';
}
.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .has-image
  .opinion_body.byline {
  grid-template-areas: 'image byline byline' 'image title title' 'image . premium';
  grid-template-rows: auto auto 1fr;
}
.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .has-image
  .opinion_body.byline.pills {
  grid-template-areas: 'image pills' 'image byline' 'image title' 'image premium';
  grid-template-rows: auto 1fr;
  --b-teaser-padding-overlay-byline: var(--brick-space-teaserBoxInsetM) 0 0 0;
}

.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .has-image
  .opinion_body.byline.pills
  .opinion-pills {
  grid-area: pills;
}
.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .opinion_body
  .byline_wrapper {
  grid-area: byline;
}

.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .opinion_body
  .opinion-pills {
  grid-area: pills;
  justify-self: end;
  align-self: start;
}

/* solo-group opinion mirror styles */
.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .opinion_body.mirror.pills {
  grid-template-areas: 'pills .' 'title premium';
}
.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .has-image
  .opinion_body.mirror {
  grid-template-columns: auto 30%;
  grid-template-areas: 'title image';
}
.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .has-image
  .opinion_body.mirror.pills {
  grid-template-areas: 'pills image' 'title image';
  grid-template-rows: auto;
}
.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .has-image
  .opinion_body.mirror.byline {
  grid-template-columns: auto 30%;
  grid-template-areas: 'byline image' 'title image';
  grid-template-rows: auto;
}
.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .has-image
  .opinion_body.mirror.byline.pills {
  grid-template-areas: 'pills image' 'byline image' 'title image';
}
.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .opinion_body.mirror
  .opinion-pills {
  justify-self: start;
}
.solo-group
  brick-teaser-v17[data-teaser-type='opinion']
  .has-image
  .opinion_body.mirror
  .premium_container {
  --b-teaser-padding-opinion-premium-wrap: var(--brick-space-teaserBoxInsetM);
  grid-area: image;
  z-index: 2;
}
/* end solo-group opinion mirror styles */

.solo-group brick-teaser-v17 .bubble {
  grid-template-columns: 30% 1fr auto;
  grid-template-rows: auto;
  grid-template-areas: 'image title premium';
  column-gap: var(--brick-space-teaserStackAL);
  grid-auto-flow: row;
  position: relative;
}
.solo-group brick-teaser-v17 .bubble.mirror {
  grid-template-columns: 1fr 30%;
  grid-template-areas: 'title image';
}
.solo-group brick-teaser-v17 .bubble.mirror .premium_container {
  grid-area: image;
}
.solo-group brick-teaser-v17 .bubble.pills {
  grid-template-areas: 'image pills' 'image title';
}
.solo-group brick-teaser-v17 .bubble.pills.mirror {
  grid-template-areas: 'pills image' 'title image';
}

@media (min-width: 532px) {
  .rodimus-complex-front [data-theme='bravo'] .countdown {
    --b-teaser-padding-footer: var(--brick-space-teaserFooterTipusTopL) 0 0 0;
  }
  .solo-group brick-teaser-v17[data-teaser-type='opinion'] .opinion_body {
    --b-teaser-padding-content-top: var(--brick-space-teaserContentYInsetL);
    --b-teaser-padding-content-right: var(--brick-space-teaserContentXInsetL);
    --b-teaser-padding-content-bottom: var(--brick-space-teaserContentYInsetL);
    --b-teaser-padding-content-left: var(--brick-space-teaserContentXInsetL);
    --b-teaser-padding-overlay: var(--brick-space-teaserContentXInsetL)
      var(--brick-space-teaserContentXInsetL) 0 0;
  }
  .solo-group
    brick-teaser-v17[data-teaser-type='opinion']
    .has-image
    .opinion_body.byline.pills {
    grid-template-areas: 'image byline pills' 'image title title' 'image . premium';
  }
  .solo-group
    brick-teaser-v17[data-teaser-type='opinion']
    .has-image
    .opinion_body.mirror.byline {
    grid-template-columns: auto auto 30%;
    grid-template-areas: 'byline byline image' 'title title image';
    grid-template-rows: auto;
  }
  .solo-group
    brick-teaser-v17[data-teaser-type='opinion']
    .has-image
    .opinion_body.mirror.byline.pills {
    grid-template-areas: 'byline pills image' 'title title image';
  }
  .solo-group
    brick-teaser-v17[data-teaser-type='opinion']
    .has-image
    .opinion_body.mirror.byline.pills
    .opinion-pills {
    justify-self: end;
  }
}

@container teaser-content (width < 580px) {
  .solo-group
    brick-teaser-v17[data-teaser-type='opinion']
    .has-image
    .opinion_body.mirror.byline {
    grid-template-columns: auto auto 30%;
    grid-template-areas: 'byline byline image' 'title title image';
    grid-template-rows: auto;
  }
  .solo-group
    brick-teaser-v17[data-teaser-type='opinion']
    .has-image
    .opinion_body.mirror.byline.pills {
    grid-template-areas: 'byline pills image' 'title title image';
  }
}

@container teaser-content (width > 780px) {
  .solo-group
    brick-teaser-v17[data-teaser-type='opinion']
    .title_container
    .title {
    --brick-teaser-cqi-clamp: clamp(
      var(--brick-fontSizes-titleOpinionS),
      var(--brick-sizes-titleCqi) + var(--brick-fontSizes-titleCqiBase),
      var(--brick-fontSizes-titleOpinionXl)
    );
  }
}

/* end solo-group opinion styles */

/* solo-group feature styles */
.solo-group brick-teaser-v17[data-teaser-type='feature'] {
  --b-teaser-padding: 0;
  --b-teaser-padding-pill-wrap: var(--brick-space-teaserContentYInsetM)
    var(--brick-space-teaserContentXInsetM) 2px;
  --b-teaser-padding-content-top: var(--brick-space-teaserContentYInsetM);
  --b-teaser-padding-content-right: var(--brick-space-teaserContentXInsetM);
  --b-teaser-padding-content-left: var(--brick-space-teaserContentXInsetM);
}
.solo-group
  brick-teaser-v17[data-teaser-type='feature']
  .title_container
  .title {
  --brick-teaser-solo-cqi: 1.2cqi;
  --brick-teaser-cqi-clamp: clamp(
    var(--brick-fontSizes-titleFeatureXs),
    var(--brick-sizes-titleCqi) + var(--brick-fontSizes-titleCqiBase),
    var(--brick-fontSizes-titleFeatureM)
  );
}

.solo-group
  brick-teaser-v17[data-teaser-type='feature']:has(.skin)
  .teaser_container {
  --b-teaser-padding: var(--brick-space-teaserSkinInsetM);
}
.solo-group brick-teaser-v17[data-teaser-type='feature'] .teaser_body {
  grid-template-areas: 'title';
}
.solo-group brick-teaser-v17[data-teaser-type='feature'] .premium_container {
  --b-teaser-padding-premium-wrap: 0;
  justify-self: end;
}
.solo-group
  brick-teaser-v17[data-teaser-type='feature']:has(.premium)
  .teaser_body {
  grid-template-areas: 'title premium';
}
.solo-group
  brick-teaser-v17[data-teaser-type='feature']:has(.has-image)
  .teaser_body {
  grid-template-areas: 'image title';
}
.solo-group
  brick-teaser-v17[data-teaser-type='feature']:has(.has-image)
  .premium_container {
  --b-teaser-padding-premium-wrap: var(--brick-space-teaserContentYInsetM)
    var(--brick-space-teaserContentXInsetM);
}
.solo-group
  brick-teaser-v17[data-teaser-type='feature']:has(.premium.has-image)
  .teaser_body {
  grid-template-areas: 'image title' 'image premium';
  grid-template-rows: 1fr auto;
}
.solo-group
  brick-teaser-v17[data-teaser-type='feature']:has(.premium.has-image)
  .teaser_body.pills {
  grid-template-areas: 'image pills' 'image title' 'image premium';
  grid-template-rows: auto auto 1fr;
}
.solo-group brick-teaser-v17[data-teaser-type='feature'] .feature-pills {
  grid-area: pills;
}

/* solo-group feature mirror */
.solo-group
  brick-teaser-v17[data-teaser-type='feature']:has(.has-image):has(.mirror)
  .teaser_body {
  --b-teaser-padding-content-left: var(--brick-space-teaserContentXInsetM);
  grid-template-areas: 'title image';
}
.solo-group
  brick-teaser-v17[data-teaser-type='feature']:has(.has-image):has(.mirror)
  .premium_container {
  grid-area: image;
  z-index: 3;
}
.solo-group
  brick-teaser-v17[data-teaser-type='feature']:has(.has-image):has(
    .mirror.pills
  )
  .teaser_body {
  grid-template-areas: 'pills image' 'title image' 'premium image';
}

@media (min-width: 532px) {
  .solo-group brick-teaser-v17:has(.skin) {
    --b-teaser-padding: var(--brick-space-teaserSkinInsetL);
  }
  .solo-group brick-teaser-v17[data-teaser-type='feature'] {
    --b-teaser-padding-pill-wrap: var(--brick-space-teaserContentYInsetL)
      var(--brick-space-teaserContentXInsetL) 2px;
    --b-teaser-padding-content-top: var(--brick-space-teaserContentYInsetL);
    --b-teaser-padding-content-right: var(--brick-space-teaserContentXInsetL);
    --b-teaser-padding-content-left: var(--brick-space-teaserContentXInsetL);
  }
}

@container teaser-content (width > 780px) {
  .solo-group
    brick-teaser-v17[data-teaser-type='feature']
    .title_container
    .title {
    --brick-teaser-cqi-clamp: clamp(
      var(--brick-fontSizes-titleFeatureS),
      var(--brick-sizes-titleCqi) + var(--brick-fontSizes-titleCqiBase),
      var(--brick-fontSizes-titleFeatureXl)
    );
  }
}

/* solo-group feature styles end */

@supports (container: inline-size) {
  .solo-group brick-teaser-v17 .title_container .title {
    --_brick-teaser-calc-max-letters: max(
      var(--brick-teaser-title-length, 0),
      5
    );
    --brick-teaser-solo-cqi: 1.8cqi;
    --brick-teaser-solo-text-calc: calc(
      (100 / var(--_brick-teaser-calc-max-letters)) *
        var(--brick-teaser-solo-cqi)
    );
    font-size: max(
      var(--brick-teaser-cqi-clamp),
      var(--brick-teaser-solo-text-calc)
    );
  }
}

@container teaser-content (width > 500px) {
  .solo-group brick-teaser-v17 .title_container .title {
    --brick-teaser-solo-cqi: 1.2cqi;
  }
  .solo-group
    brick-teaser-v17:has([data-breaking-news='true'])
    .title_container
    .title {
    --brick-teaser-cqi-clamp: clamp(
      var(--brick-fontSizes-titleBreakingS),
      var(--brick-sizes-titleBreakingCqi) + var(--brick-fontSizes-titleCqiBase),
      var(--brick-fontSizes-titleBreakingXxl)
    );
  }
}

@container teaser-content (width > 680px) {
  .solo-group brick-teaser-v17 .title_container .title {
    --brick-teaser-cqi-clamp: clamp(
      var(--brick-fontSizes-titleS),
      var(--brick-sizes-titleCqi) + var(--brick-fontSizes-titleCqiBase),
      var(--brick-fontSizes-titleXxl)
    );
  }
}

/* solo group styles end */
