.rodimus-front {
  position: relative;
}

optimus-element {
  display: block;
  box-sizing: content-box !important;
}

/* .rodimus-front bazaar-ad:not(.bazaar-parallax-x2), */
/* .rodimus-front tivoli-realestatecarousel {
  margin-top: var(--brick-space-deckCommercialYGapL) !important;
  margin-bottom: var(--brick-space-deckCommercialYGapL) !important;
} */
.rodimus-front tivoli-realestatecarousel {
  overflow: hidden;
}

.rodimus-front
  amedia-include:has(amedia-smartembed):has(
    [data-component-layout='editorial']
  ) {
  margin-bottom: var(--brick-space-deckYGapL);
  margin-top: var(--brick-space-deckYGapL);
}

/* Targets inline embeds, that should fill available space in grid **/
.rodimus-front
  optimus-element[unknown-teaser-content='true']
  amedia-include:has(amedia-smartembed):has(
    [data-component-layout='editorial'],
    [data-component-layout='commercial']
  ) {
  margin-bottom: 0;
  margin-top: 0;
}

.rodimus-front
  amedia-include:has(amedia-smartembed):has(
    [data-component-layout='commercial']
  ) {
  margin-bottom: var(--brick-space-deckCommercialYGapL);
  margin-top: var(--brick-space-deckCommercialYGapL);
}
/* @media screen and (min-width: 533px) and (max-width: 1000px) {
  .rodimus-front bazaar-ad,
  .rodimus-front tivoli-realestatecarousel {
    margin-top: var(--brick-space-deckCommercialYGapM);
    margin-bottom: var(--brick-space-deckCommercialYGapM) !important;
  }
} */
@media screen and (max-width: 532px) {
  /* .rodimus-front bazaar-ad,
  .rodimus-front tivoli-realestatecarousel {
    margin-top: var(--brick-space-deckCommercialYGapM);
    margin-bottom: var(--brick-space-deckCommercialYGapM) !important;
  } */

  /* amedia-include*/

  .rodimus-front
    amedia-include:has(amedia-smartembed):has(
      [data-component-layout='commercial']
    ) {
    margin-bottom: var(--brick-space-deckCommercialYGapM, 15px);
    margin-top: var(--brick-space-deckCommercialYGapM, 15px);
  }

  .rodimus-front
    amedia-include:has(amedia-smartembed):has(
      [data-component-layout='editorial']
    ),
  .rodimus-front amedia-include:has(amedia-smartembed):has(brick-teaser),
  .rodimus-front amedia-include:has(amedia-smartembed):has(brick-teaser-v15),
  .rodimus-front amedia-include:has(amedia-smartembed):has(brick-teaser-v17) {
    margin-bottom: var(--brick-space-deckYGapM, 15px);
    margin-top: var(--brick-space-deckYGapM, 15px);
    margin-left: 0;
    margin-right: 0;
  }

  .rodimus-front bazaar-ad:empty {
    margin-bottom: 0 !important;
  }

  #visualize-personalization-overlay {
    max-width: 75vw;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    background: #8b0000b3;
    font-family: Roboto, sans-serif;
    margin-left: 1vw;
    position: fixed;
    top: 100px;
    z-index: 999999;
    padding: 0 50px 40px 50px;
    color: #ffffff;
  }

  @media screen and (max-width: 500px) {
    #visualize-personalization-overlay {
      font-size: 10px;
      max-width: 100%;
      padding: 0 10px;
      top: 30px;
    }

    #visualize-personalization-overlay ul li {
      line-height: 20px;
    }
  }

  #visualize-personalization-overlay .personalized-article-scroll:hover {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

amedia-img {
  min-height: 1px;
  display: block;
}

brick-carousel > section > ul.contents-wrapper > li.content-wrapper {
  min-height: 1px;
}

brick-carousel amedia-laserbeak-v0 {
  display: block;
  height: inherit;
}

@keyframes popcorn-overlay-transform {
  0% {
    display: none;
    transform: translate3d(100%, 0, 0);
  }
  1% {
    display: block;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    display: block;
    transform: none;
  }
}

#frontpage #popcorn-overlay,
#forsiden-www-nettavisen-no #popcorn-overlay {
  display: none;
  position: fixed;
  inset: 0;
  overflow-y: auto;
  transform: translate3d(100%, 0, 0);
  z-index: 99999;
  background-color: rgb(41, 40, 39);
}

#frontpage #popcorn-overlay.active,
#forsiden-www-nettavisen-no #popcorn-overlay.active {
  display: block;
  animation: popcorn-overlay-transform 0.4s forwards;
}
